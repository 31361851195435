// COMPONENTS
import React, { createElement } from 'react'
// BOOTSTRAP
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
// SPINNER SET
import * as Spinners from 'react-spinners'

// React Spinner component
const Spinner = ({ name='BeatLoader', size='50px', margin='20px', color='var(--info)'}) => {
  let spinner = null
  try{
    spinner = createElement(Spinners[name], {size, color, margin })
  } catch (err) { console.err(err) }
  
  return (
  <>
  { spinner && 
    <Row>
      <Col className='d-flex justify-content-center my-5' xs={12}>
      {spinner}
      </Col>
    </Row>
  }
  </>
  )
}

export default Spinner